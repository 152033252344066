import React from 'react';

import { useTheme } from '@components/theme';
import classNames from 'classnames';
import styles from './CarouselNavigator.module.scss';

interface INavigatorProps {
  className?: string;
  navPrev?: string;
  navNext?: string;
  onClickPrev?: () => void;
  onClickNext?: () => void;
}

export default function CarouselNavigator({
  className,
  navPrev,
  navNext,
  onClickPrev = () => {},
  onClickNext = () => {}
}: INavigatorProps) {
  const theme = useTheme();
  const prev = classNames(navPrev, styles.Prev, theme.styles.Button);
  const next = classNames(navNext, styles.Next, theme.styles.Button);

  return (
    <div className={ classNames(className, styles.Navigator) }>
      <button
        className={prev}
        type="button"
        onClick={onClickPrev}
      >
        prev
      </button>
      <hr />
      <button
        className={next}
        type="button"
        onClick={onClickNext}
      >
        next
      </button>
    </div>
  );
}
